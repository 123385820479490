<template>
  <div>
    <!--modal rekap start-->
    <b-modal id="modal-rekap" ok-only centered size="lg" content-class="shadow" hide-footer>
      <template #modal-title>
        <div style="font-size:24px; font-weight:600;">Detail Rekapitulasi Ormas Terdaftar</div>
      </template>
      <div>
        <b-tabs pills card justified v-model="tabDetail">
          <b-tab active title="Umum" style="background-color:white;margin-top:0px;">
            <b-form-row>
              <b-col>
                <b-row>
                  <b-col v-if="selectedDetail && selectedDetail.kabupaten_kota != null" style="font-weight: 800; margin:0px; text-align: center;">
                    {{ selectedDetail.kabupaten_kota }}
                  </b-col>
                  <b-col v-if="selectedDetail && selectedDetail.provinsi != null" style="font-weight: 800; margin:0px; text-align: center;">
                    {{ selectedDetail.provinsi }}
                  </b-col>
                  <b-col v-if="selectedDetail && !selectedDetail.kode" style="font-weight: 800; margin:0px; text-align: center;"> NASIONAL </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <Pie style="height: 200px" :chart-options="chartOptionsTotal" :chart-data="chartDataTotal" />
                  </b-col>
                </b-row>
                <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
                  <b-col sm="4">
                    <p style="font-weight: 800; margin-top:35px; text-align: center;">Jumlah Ormas</p>
                  </b-col>
                  <!-- :<b-col sm="4" v-if="detailRekapitulasi">
                        {{ ('jumlah_ormas' in detailRekapitulasi) ? detailRekapitulasi.jumlah_ormas : detailRekapitulasi.total }}
                    </b-col> -->
                </b-row>
                <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
                  <b-col sm="4">
                    <p style="font-weight: 600; margin:0px;"> 1. Jumlah Ormas Aktif</p>
                  </b-col>: <b-col sm="4" v-if="detailRekapitulasi">
                    {{ ('jumlah_ormas_aktif' in detailRekapitulasi) ? detailRekapitulasi.jumlah_ormas_aktif : detailRekapitulasi.status_skt[0].status.aktif }}
                  </b-col>
                </b-row>
                <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
                  <b-col sm="4">
                    <p style="font-weight: 600; margin:0px;"> 2. Jumlah Ormas Tidak Aktif</p>
                  </b-col>: <b-col sm="4" v-if="detailRekapitulasi">
                    {{ ('jumlah_ormas_tidak_aktif' in detailRekapitulasi) ? detailRekapitulasi.jumlah_ormas_tidak_aktif : detailRekapitulasi.status_skt[0].status.tidak_aktif }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p style="font-weight: 800; margin-top:35px; text-align: center;">Ciri Khusus</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <Pie style="height: 200px" :chart-options="chartOptionsCiriKhusus" :chart-data="chartDataCiriKhusus" />
                  </b-col>
                </b-row>
                <b-row class="d-flex align-items-center mt-2" style="justify-content: center;" v-for="(item,i) in listDetail.ciri_khusus" :key="'ciri-'+i">
                  <b-col sm="4">
                    <p style="font-weight: 600; margin:0px;">{{item.label}}</p>
                  </b-col>: <b-col sm="4">
                    {{item.status.aktif}}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p style="font-weight: 800; margin-top:35px; text-align: center;">Jenjang Ormas</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <Pie style="height: 200px" :chart-options="chartOptionsJenjangOrmas" :chart-data="chartDataJenjangOrmas" />
                  </b-col>
                </b-row>
                <b-row class="d-flex align-items-center mt-2" style="justify-content: center;" v-for="(item,i) in listDetail.jenjang_ormas" :key="'jenjang-'+i">
                  <b-col sm="4">
                    <p style="font-weight: 600; margin:0px;">{{item.label}}</p>
                  </b-col>: <b-col sm="4">
                    {{item.status.aktif}}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p style="font-weight: 800; margin-top:35px; text-align: center;">Status Kegiatan</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <Pie style="height: 200px" :chart-options="chartOptionsStatusKegiatan" :chart-data="chartDataStatusKegiatan" />
                  </b-col>
                </b-row>
                <b-row class="d-flex align-items-center mt-2" style="justify-content: center;" v-for="(item,i) in listDetail.status_kegiatan" :key="'kegiatan-'+i">
                  <b-col sm="4">
                    <p style="font-weight: 600; margin:0px;">{{item.label}}</p>
                  </b-col>: <b-col sm="4">
                    {{item.status.aktif}}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p style="font-weight: 800; margin-top:35px; text-align: center;">Ormas Dalam Sengketa</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <Pie style="height: 200px" :chart-options="chartOptionsDalamSengketa" :chart-data="chartDataDalamSengketa" />
                  </b-col>
                </b-row>
                <b-row class="d-flex align-items-center mt-2" style="justify-content: center;" v-for="(item,i) in listDetail.ormas_dalam_sengketa" :key="'ordas-'+i">
                  <b-col sm="4">
                    <p style="font-weight: 600; margin:0px;">{{item.label}}</p>
                  </b-col>: <b-col sm="4">
                    {{item.status.aktif}}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p style="font-weight: 800; margin-top:35px; text-align: center;">Status Sekretariat</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <Pie style="height: 200px" :chart-options="chartOptionsStatusSekretariat" :chart-data="chartDataStatusSekretariat" />
                  </b-col>
                </b-row>
                <b-row class="d-flex align-items-center mt-2" style="justify-content: center;" v-for="(item,i) in listDetail.status_sekretariat" :key="'sekre-'+i">
                  <b-col sm="4">
                    <p style="font-weight: 600; margin:0px;">{{item.label}}</p>
                  </b-col>: <b-col sm="4">
                    {{item.status.aktif}}
                  </b-col>
                </b-row>
              </b-col>
            </b-form-row>
          </b-tab>
          <b-tab title="Bidang Kegiatan" style="background-color:white;margin-top:0px;">
            <b-form-row>
              <b-col>
                <b-row>
                  <b-col v-if="selectedDetail && selectedDetail.kabupaten_kota != null" style="font-weight: 800; margin:0px; text-align: center;">
                    {{selectedDetail.kabupaten_kota}}
                  </b-col>
                  <b-col v-if="selectedDetail && selectedDetail.provinsi != null" style="font-weight: 800; margin:0px; text-align: center;">
                    {{selectedDetail.provinsi}}
                  </b-col>
                  <b-col v-if="selectedDetail && !selectedDetail.kode" style="font-weight: 800; margin:0px; text-align: center;"> NASIONAL </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <Pie style="height: 800px" :chart-options="chartOptionsBidangKegiatan" :chart-data="chartDataBidangKegiatan" />
                  </b-col>
                </b-row>
                <b-row class="d-flex align-items-center mt-2" style="justify-content: center;" v-for="(item,i) in detailBidangKegiatan" :key="'bidang-'+i">
                  <b-col sm="7">
                    <p :class="item.total != '0' ? 'tb' : 'tl'">{{item.nama_jenis_ormas}}</p>
                  </b-col>: <b-col sm="4" :class="item.total != '0' ? 'tb' : 'tl'">
                    {{item.total_aktif}}
                  </b-col>
                </b-row>
              </b-col>
            </b-form-row>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
    <!--modal rekap end-->

    <!--modal rekap bakum start-->
    <b-modal id="modal-rekap-bakum" ok-only centered size="lg" content-class="shadow" hide-footer>
      <template #modal-title>
        <div style="font-size:24px; font-weight:600;">Detail Rekapitulasi Ormas Badan Hukum</div>
      </template>
      <div>
        <b-form-row>
          <b-col>
            <b-row>
              <b-col v-if="typeUser === 'admin' && namaProv !== '' && kodeKab === ''" style="font-weight: 800; margin:0px; text-align: center;">
                {{ namaProv }}
              </b-col>
              <b-col v-if="typeUser === 'prov' && namaProv !== '' && kodeKab === ''" style="font-weight: 800; margin:0px; text-align: center;">
                {{ namaProv }}
              </b-col>
              <b-col v-if="typeUser === 'kab' && namaProv === '' && kodeKab !== ''" style="font-weight: 800; margin:0px; text-align: center;">
                {{ namaKab }}
              </b-col>
              <b-col v-if="typeUser === 'admin' && namaProv === '' && kodeKab !== ''" style="font-weight: 800; margin:0px; text-align: center;">
                {{ namaKab }}
              </b-col>
              <b-col v-if="typeUser === 'admin' && namaProv === '' && kodeKab === ''" style="font-weight: 800; margin:0px; text-align: center;"> NASIONAL </b-col>
            </b-row>
            <b-row>
              <b-col>
                <Pie style="height: 200px" :chart-options="chartOptionsTotalBakum" :chart-data="chartDataTotalBakum" />
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
              <b-col sm="4">
                <p style="font-weight: 800; margin-top:35px; text-align: center;">Jumlah Ormas Badan Hukum</p>
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;"> 1. Jumlah Ormas Perkumpulan</p>
              </b-col>: <b-col sm="4" v-if="!countingNasional">
                {{ totsPerkumpulan }}
              </b-col>
              <b-col sm="4" v-if="countingNasional">
                {{ totsPerkumpulan }}
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;"> 2. Jumlah Ormas Yayasan</p>
              </b-col>: <b-col sm="4" v-if="!countingNasional">
                {{ totsYayasan }}
              </b-col>
              <b-col sm="4" v-if="countingNasional">
                {{ totsYayasan }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p style="font-weight: 800; margin-top:35px; text-align: center;">Kelengkapan Nama Ormas Badan Hukum</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <Pie style="height: 200px" :chart-options="chartOptionsKelengkapanNamaBakum" :chart-data="chartDataKelengkapanNamaBakum" />
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;"> 1. Jumlah Ormas Tanpa Nama</p>
              </b-col>: <b-col sm="4" v-if="!countingNasional">
                {{ totsTanpaNama }}
              </b-col>
              <b-col sm="4" v-if="countingNasional">
                {{ totsTanpaNama }}
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;"> 2. Jumlah Ormas Nama Lengkap</p>
              </b-col>: <b-col sm="4" v-if="!countingNasional">
                {{ totsNamaLengkap }}
              </b-col>
              <b-col sm="4" v-if="countingNasional">
                {{ totsNamaLengkap }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p style="font-weight: 800; margin-top:35px; text-align: center;">Kelengkapan Akta Pendirian Ormas Badan Hukum</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <Pie style="height: 200px" :chart-options="chartOptionsKelengkapanAKTABakum" :chart-data="chartDataKelengkapanAKTABakum" />
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;"> 1. Jumlah Ormas Tanpa Nomor Akta Pendirian</p>
              </b-col>: <b-col sm="4" v-if="!countingNasional">
                {{ totsTanpaAKTA }}
              </b-col>
              <b-col sm="4" v-if="countingNasional">
                {{ totsTanpaAKTA }}
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;"> 2. Jumlah Ormas Memiliki Nomor Akta Pendirian</p>
              </b-col>: <b-col sm="4" v-if="!countingNasional">
                {{ totsAKTALengkap }}
              </b-col>
              <b-col sm="4" v-if="countingNasional">
                {{ totsAKTALengkap }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p style="font-weight: 800; margin-top:35px; text-align: center;">Kelengkapan SK Kumham Ormas Badan Hukum</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <Pie style="height: 200px" :chart-options="chartOptionsKelengkapanSKBakum" :chart-data="chartDataKelengkapanSKBakum" />
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;"> 1. Jumlah Ormas Tanpa Nomor SK Kumham</p>
              </b-col>: <b-col sm="4" v-if="!countingNasional">
                {{ totsTanpaSK }}
              </b-col>
              <b-col sm="4" v-if="countingNasional">
                {{ totsTanpaSK }}
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mt-2" style="justify-content: center;">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;"> 2. Jumlah Ormas Memiliki Nomor SK Kumham</p>
              </b-col>: <b-col sm="4" v-if="!countingNasional">
                {{ totsSKLengkap }}
              </b-col>
              <b-col sm="4" v-if="countingNasional">
                {{ totsSKLengkap }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p style="font-weight: 800; margin-top:35px; text-align: center;">Kelengkapan Kompilasi Data Ormas Badan Hukum</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <Pie style="height: auto" :chart-options="chartOptionsKompilasiDataBakum" :chart-data="chartDataKompilasiDataBakum" />
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mt-2" style="justify-content: center;" v-for="(item, i) in labelKompilasi" :key="i">
              <b-col sm="4">
                <p style="font-weight: 600; margin:0px;"> {{ item }}</p>
              </b-col>: <b-col sm="4" v-if="!countingNasional">
                {{ totsKompilasi[i] }}
              </b-col>
              <b-col sm="4" v-if="countingNasional">
                {{ totsKompilasi[i] }}
              </b-col>
            </b-row>
          </b-col>
        </b-form-row>
      </div>
    </b-modal>
    <!--modal rekap bakum end-->

    <div :v-model="tab" class="tabs shadow" style="border-radius:10px;">
      <div class="card-header">
        <ul role="tablist" class="nav nav-pills card-header-pills nav-justified">
          <li class="nav-item" role="presentation" @click="terdaftarormas()">
            <a id="rekap01" tab=0 role="tab" aria-selected="true" aria-setsize="4" aria-posinset="1" href="#" target="_self" class="nav-link active">Terdaftar</a>
          </li>
          <li class="nav-item" role="presentation">
            <a id="rekap02" role="tab" tabindex="-1" aria-selected="false" aria-setsize="4" aria-posinset="2" href="#" target="_self" class="nav-link disabled disabled" aria-disabled="true">Ormas WNA</a>
          </li>
          <li class="nav-item" role="presentation" @click="badanhukumormas()">
            <a id="rekap03" tab=2 role="tab" tabindex="-1" aria-selected="false" aria-setsize="4" aria-posinset="3" href="#" target="_self" class="nav-link deactive">Badan Hukum</a>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="terdaftar" class="tab-pane card-body" style="background-color: white; padding: 1rem 2.25rem 2.25rem 2.25rem !important;">
      <b-row class="mt-5">
          <b-col style="flex: unset !important; max-width: unset !important;" cols="6" class="d-flex align-items-center justify-content-between">
            <span v-if="typeUser == 'kab' " style="font-size:16px; font-weight:bold;">Rekapitulasi Ormas Terdaftar {{listData[0] ? listData[0].kabupaten_kota : ''}}</span>
            <span v-else-if="typeUser == 'prov' " style="font-size:16px; font-weight:bold;">Rekapitulasi Ormas Terdaftar Propinsi {{wilayahUser.nama}}</span>
            <span v-else-if="typeCluster[0] == 2 " style="font-size:16px; font-weight:bold;">Rekapitulasi Ormas Terdaftar Propinsi {{selected.text}}</span>
            <span v-else-if="typeCluster[0] == 1 " style="font-size:16px; font-weight:bold;">Rekapitulasi Terdaftar Per Provinsi - Nasional</span>
            <!-- <span v-else style="font-size:16px; font-weight:bold;">Tabel Rekapitulasi Ormas - {{ typeUser !== 'kab' ? 'Nasional' : selected.text}}</span> -->
          </b-col>
          <!-- <b-col cols="6" class="d-flex align-items-center justify-content-end" ><section v-if="typeCluster[0] == 2"><b-button v-if="typeUser !== 'kab' && typeUser !== 'prov'" variant="outline-secondary" class="mr-3" size="sm" @click="resetMap()"><v-icon class="pr-3">mdi-arrow-left</v-icon>
                  Kembali
                </b-button></section><button
              class="btn font-weight-bolder font-size-sm mr-3 bg-hitam"
              style="color:white;"
              data-wizard-type="action-submit"
              @click="cetakEXCEL()"
            ><i class="fas fa-file-excel"></i>Ekspor ke Excel
            </button><button
              class="btn font-weight-bolder font-size-sm bg-hitam"
              style="color:white;"
              data-wizard-type="action-submit"
              @click="cetakPDF()"
            ><i class="fas fa-file-pdf"></i>Cetak PDF
            </button></b-col> -->
        </b-row>
        <b-container class="pl-4" v-if="typeUser !== 'kab'" style="padding-right: 1rem !important;">
          <b-row class="pl-0 pt-4">
            <div style="width:100%;">
              <l-map style="height: 500px; border-radius: 15px;" ref="myMap" :zoom="zoomIgnite" :center="initialLocation" :options="{zoomControl: false, scrollWheelZoom: false, doubleClickZoom:false, dragging:false}">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker v-for="(l, i) in locations" :key="i" :lat-lng="l.latlng" :icon="icon">
                  <l-tooltip>{{ l.text }}</l-tooltip>
                </l-marker>
              </l-map>
            </div>
          </b-row>
        </b-container>
        <template id="">
          <Bar :chart-options="chartOptions" :chart-data="chartData" :height="height" />
        </template>
        <b-container class="pr-4 pl-4">
          <b-row class="mt-5">
            <b-col cols="6" class="d-flex align-items-center justify-content-between">
              <button class="btn font-weight-bolder font-size-sm bg-hitam" style="color:white;" @click="detail()" v-if="typeUser !== 'kab'">
                <i class="fas fa-info"></i>Detail Rekap {{this.typeCluster[0] == 1 ? 'Nasional' : 'Provinsi'}}
              </button>
            </b-col>
            <b-col cols="6" class="d-flex align-items-center justify-content-end">
              <section v-if="typeCluster[0] == 2">
                <b-button v-if="typeUser !== 'kab' && typeUser !== 'prov'" variant="outline-secondary" class="mr-3" size="sm" @click="resetMap()">
                  <v-icon class="pr-3">mdi-arrow-left</v-icon> Kembali
                </b-button>
              </section>
              <button class="btn font-weight-bolder font-size-sm mr-3 bg-hitam" style="color:white;" data-wizard-type="action-submit" @click="cetakEXCEL()">
                <i class="fas fa-file-excel"></i>Ekspor ke Excel </button>
              <button class="btn font-weight-bolder font-size-sm bg-hitam" style="color:white;" data-wizard-type="action-submit" @click="cetakPDF()">
                <i class="fas fa-file-pdf"></i>Cetak PDF </button>
            </b-col>
          </b-row>
          <b-row>
            <div class="table-responsive mt-4" style="border-radius:15px;">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              " style="font-size:14px !important;">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px" class="text-capitalize">No.</th>
                    <th v-if="typeCluster[0] == 1 " style="min-width: 50px" class="text-capitalize">Kesbangpol Provinsi</th>
                    <th v-if="typeCluster[0] == 2 " style="min-width: 50px" class="text-capitalize">Kesbangpol Kabupaten/Kota</th>
                    <th style="min-width: 50px" class="text-capitalize">Jumlah Ormas</th>
                    <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                  </tr>
                </thead>
                <tbody style="background: #FFFFFF;">
                  <template v-for="(item, i) in paginateData">
                    <tr v-bind:key="'list-wilayah-'+i">
                      <td>
                        <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ (i+1) + ((currentPage-1)*perPage)}}</span>
                      </td>
                      <td class="text-left">
                        <span v-if="typeCluster[0] == 1" class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          font-size-lg
                        "> {{item.provinsi}}
                        </span>
                        <span v-if="typeCluster[0] == 2" class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          font-size-lg
                        "> {{item.kabupaten_kota}}
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          font-size-lg
                        "> {{item.jumlah_ormas_aktif}}
                        </span>
                      </td>
                      <td>
                        <section>
                          <b-button variant="success" size="sm" style="padding:5px" class="mr-1" @click="detail(item)"> Detail Rekap </b-button>
                          <b-button v-if="typeCluster[0] == 1" variant="success" size="sm" style="padding:5px" class="mr-1" @click="dataClicked(item)"> Rincian Kabupaten/Kota </b-button>
                        </section>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <b-row>
              <b-col class="d-flex justify-content-end">
                <b-pagination v-model="currentPage" :total-rows="listData.length" :per-page="perPage" @change="fetchTable()" aria-controls="my-table"></b-pagination>
              </b-col>
            </b-row>
          </b-row>
        </b-container>
    </div>
    <div v-if="badanHukum" class="tab-pane card-body" style="background-color: white; padding: 1rem 2.25rem 2.25rem 2.25rem !important;">
      <b-row class="mt-5">
          <b-col style="flex: unset !important; max-width: unset !important;" cols="6" class="d-flex align-items-center justify-content-between">
            <span v-if="typeUser === 'kab' && namaProv === '' && namaKab !== ''" style="font-size:16px; font-weight:bold;">Rekapitulasi Ormas Badan Hukum {{ namaKab }}</span>
            <span v-else-if="typeUser === 'prov' && namaProv !== '' && namaKab === ''" style="font-size:16px; font-weight:bold;">Rekapitulasi Ormas Badan Hukum Propinsi {{ namaProv }}</span>
            <span v-else-if="typeUser === 'admin' && namaProv !== '' && namaKab === ''" style="font-size:16px; font-weight:bold;">Rekapitulasi Ormas Badan Hukum Propinsi {{ namaProv }}</span>
            <span v-else style="font-size:16px; font-weight:bold;">Rekapitulasi Ormas Badan Hukum Per Provinsi - Nasional</span>
            <!-- <span v-else style="font-size:16px; font-weight:bold;">Tabel Rekapitulasi Ormas - {{ typeUser !== 'kab' ? 'Nasional' : selected.text}}</span> -->
          </b-col>
        </b-row>
        <b-container class="pl-4" v-if="typeUser !== 'kab'" style="padding-right: 1rem !important;">
          <b-row class="pl-0 pt-4">
            <div style="width:100%;">
              <l-map style="height: 500px; border-radius: 15px;" ref="myMapBakum" @ready="countormasbakum()" :zoom="zoomIgniteBadanHukum" :center="initialLocationBadanHukum" :options="{zoomControl: false, scrollWheelZoom: false, doubleClickZoom:false, dragging:false}">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker v-for="(l, i) in locationsbakum" :key="i" :lat-lng="l.latlng" :icon="icon"  :options="clusterOptionsBadanHukum">
                  <l-tooltip>{{ l.text }}</l-tooltip>
                </l-marker>
              </l-map>
            </div>
          </b-row>
        </b-container>
        <template id="">
          <Bar :chart-options="chartOptionsBadanHukum" :chart-data="chartDataBakum" :height="height" />
        </template>
        <b-container class="pr-4 pl-4">
          <b-row class="mt-5">
            <b-col cols="6" class="d-flex align-items-center justify-content-between">
              <button class="btn font-weight-bolder font-size-sm bg-hitam" style="color:white;" @click="detailBadanHukum(item)" v-if="typeUser !== 'kab'">
                <i class="fas fa-info"></i>Detail Rekap {{typeUser === 'admin' && namaProvs === '' && namaKabs === '' ? 'Nasional' : 'Provinsi'}}
              </button>
            </b-col>
            <b-col cols="6" class="d-flex align-items-center justify-content-end">
              <section >
                <b-button v-if="kembalitombol" variant="outline-secondary" class="mr-3" size="sm" @click="resetMapBakum()">
                  <v-icon class="pr-3">mdi-arrow-left</v-icon> Kembali
                </b-button>
              </section>
              <!-- <button class="btn font-weight-bolder font-size-sm mr-3 bg-hitam" style="color:white;" data-wizard-type="action-submit" @click="cetakEXCEL()">
                <i class="fas fa-file-excel"></i>Ekspor ke Excel </button>
              <button class="btn font-weight-bolder font-size-sm bg-hitam" style="color:white;" data-wizard-type="action-submit" @click="cetakPDF()">
                <i class="fas fa-file-pdf"></i>Cetak PDF </button> -->
            </b-col>
          </b-row>
          <b-row>
            <div class="table-responsive mt-4" style="border-radius:15px;">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              " style="font-size:14px !important;">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px" class="text-capitalize">No.</th>
                    <th v-if="typeCluster[0] == 1 " style="min-width: 50px" class="text-capitalize">Kesbangpol Provinsi</th>
                    <th v-if="typeCluster[0] == 2 " style="min-width: 50px" class="text-capitalize">Kesbangpol Kabupaten/Kota</th>
                    <th style="min-width: 50px" class="text-capitalize">Jumlah Ormas</th>
                    <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                  </tr>
                </thead>
                <tbody style="background: #FFFFFF;">
                  <template v-for="(itemm, i) in paginateDataBakum">
                    <tr v-bind:key="'list-wilayah-'+i">
                      <td>
                        <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ (i+1) + ((currentPageBakum-1)*perPageBakum)}}</span>
                      </td>
                      <td class="text-left">
                        <span v-if="typeCluster[0] == 1" class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          font-size-lg
                        "> {{itemm.nama}}
                        </span>
                        <span v-if="typeCluster[0] == 2" class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          font-size-lg
                        "> {{itemm.nama}}
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          font-size-lg
                        "> {{itemm.totalOrmas}}
                        </span>
                      </td>
                      <td>
                        <section>
                          <b-button variant="success" size="sm" style="padding:5px" class="mr-1" @click="detailBakum(itemm)"> Detail Rekap </b-button>
                          <b-button v-if="tabelbakum" variant="success" size="sm" style="padding:5px" class="mr-1" @click="dataClickedBakum(itemm)"> Rincian Kabupaten/Kota </b-button>
                        </section>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <b-row>
              <b-col class="d-flex justify-content-end">
                <b-pagination v-model="currentPageBakum" :total-rows="totalRowsBakum" :per-page="perPageBakum" aria-controls="my-table-badanhukum"></b-pagination>
              </b-col>
            </b-row>
          </b-row>
        </b-container>
    </div>
  </div>
</template>
<style>
  .tb {
    font-weight: 600;
    margin: 0px;
  }

  .tl {
    margin: 0px;
  }
</style>
<script>
  /* eslint-disable */
  import * as Vue2Leaflet from 'vue2-leaflet'
  import {LMap, LTileLayer, LMarker, LTooltip} from 'vue2-leaflet'
  import {
    latLng,
    icon
  } from 'leaflet'
  import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
  import iconUrl from '../../../../assets/image/images/marker-icon.png'
  import shadowUrl from '../../../../assets/image/images/marker-shadow.png'
  import {
    Bar
  } from 'vue-chartjs/legacy'
  import {
    Pie
  } from 'vue-chartjs/legacy'
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    ArcElement,
    CategoryScale,
    LinearScale
  } from 'chart.js'
  import Swal from "sweetalert2";
  import axios from "axios";
  ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale)
  export default {
    name: "Informasi",
    components: {
      Bar,
      Pie,
      'v-map': Vue2Leaflet.LMap,
      'v-tilelayer': Vue2Leaflet.LTileLayer,
      'v-icondefault': Vue2Leaflet.LIconDefault,
      'v-marker': Vue2Leaflet.LMarker,
      'v-popup': Vue2Leaflet.LPopup,
      'v-marker-cluster': Vue2LeafletMarkerCluster,
      LMap,
      LTileLayer,
      LMarker,
      LTooltip
    },
    props: {
      chartId: {
        type: String,
        default: 'bar-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 250
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      // let customicon = icon(Object.assign({},
      //   Icon.Default.prototype.options,
      //   {iconUrl, shadowUrl}
      // ))
      let customicon = icon({
        iconUrl,
        shadowUrl
      })
      return {
        url:'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        baseURL_bakkum: process.env.VUE_APP_APPLICATION_BAKUM_URL,
        totsYayasan: 0,
        totsPerkumpulan: 0,
        totsTanpaNama: 0,
        totsNamaLengkap: 0,
        totsTanpaAKTA: 0,
        totsAKTALengkap: 0,
        totsTanpaSK: 0,
        totsSKLengkap: 0,
        totsKompilasi: [],
        labelKompilasi: [],
        chartData: {
          labels: [],
          datasets: [{
            label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataBakum: {
          labels: [],
          datasets: [{
            label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataTotal: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataTotalBakum: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataKelengkapanNamaBakum: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: [],
            data: []
          }]
        },
        chartDataKelengkapanAKTABakum: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataKelengkapanSKBakum: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataKompilasiDataBakum: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataCiriKhusus: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataJenjangOrmas: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataStatusKegiatan: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataDalamSengketa: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataStatusSekretariat: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartDataBidangKegiatan: {
          labels: [],
          datasets: [{
            // label: '',
            backgroundColor: '',
            data: []
          }]
        },
        chartOptions: {
          responsive: true
        },
        chartOptionsBadanHukum: {
          responsive: true
        },
        chartOptionsTotal: {
          responsive: true,
          maintainAspectRatio: false
        },
        chartOptionsTotalBakum: {
          responsive: true,
          maintainAspectRatio: false
        },
        chartOptionsKelengkapanNamaBakum: {
          responsive: true,
          maintainAspectRatio: false
        },
        chartOptionsKelengkapanAKTABakum: {
          responsive: true,
          maintainAspectRatio: false
        },
        chartOptionsKelengkapanSKBakum: {
          responsive: true,
          maintainAspectRatio: false
        },
        chartOptionsKompilasiDataBakum: {
          responsive: true,
          maintainAspectRatio: false
        },
        chartOptionsCiriKhusus: {
          responsive: true,
          maintainAspectRatio: false
        },
        chartOptionsJenjangOrmas: {
          responsive: true,
          maintainAspectRatio: false
        },
        chartOptionsStatusKegiatan: {
          responsive: true,
          maintainAspectRatio: false
        },
        chartOptionsDalamSengketa: {
          responsive: true,
          maintainAspectRatio: false
        },
        chartOptionsStatusSekretariat: {
          responsive: true,
          maintainAspectRatio: false
        },
        chartOptionsBidangKegiatan: {
          responsive: true,
          maintainAspectRatio: false
        },
        terdaftar: true,
        badanHukum: false,
        typeUser: '',
        tabDetail: 0,
        tab: 0,
        kodeProv: '',
        kodeKab: '',
        namaProv: '',
        namaKab: '',
        namaRekap: '',
        kodeProvs: '',
        kodeKabs: '',
        namaProvs: '',
        namaKabs: '',
        listcountbakum: [],
        tabelbakum: false,
        kembalitombol: false,
        locations: [],
        locationsbakum: [],
        dataChartbakum: [],
        labelChartbakum: [],
        listLocation: [],
        currentPage: 1,
        perPage: 20,
        currentPageBakum: 1,
        perPageBakum: 20,
        countingNasional: false,
        paginateData: [],
        paginateDataBakum: [],
        totalRowsBakum: 0,
        icon: customicon,
        clusterOptions: {
          disableClusteringAtZoom: 0
        },
        clusterOptionsBadanHukum: {
          disableClusteringAtZoom: 0
        },
        initialLocation: latLng(-2.600029, 118.015776),
        initialLocationBadanHukum: latLng(-2.600029, 118.015776),
        typeCluster: [1],
        zoomIgnite: 5,
        zoomIgniteBadanHukum: 5,
        selected: null,
        selectedDetail: null,
        listData: [],
        paramsTable: {
          "offset": 0,
          "limit": 1000,
          "where": {
            "kabupaten_kota": null
          }
        },
        detailBidangKegiatan: [],
        detailRekapitulasi: null,
        tempProv: [],
        tempKab: [],
        wilayahUser: '',
        listDetail: {
          ciri_khusus: [{
            value: '',
            label: '',
            status: {
              aktif: '',
              tidak_aktif: ''
            }
          }],
          jenjang_ormas: [{
            value: '',
            label: '',
            status: {
              aktif: '',
              tidak_aktif: ''
            }
          }],
          status_kegiatan: [{
            value: '',
            label: '',
            status: {
              aktif: '',
              tidak_aktif: ''
            }
          }],
          status_sekretariat: [{
            value: '',
            label: '',
            status: {
              aktif: '',
              tidak_aktif: ''
            }
          }],
          ormas_dalam_sengketa: [{
            value: '',
            label: '',
            status: {
              aktif: '',
              tidak_aktif: ''
            }
          }],
        },
        userWilayah: {
          kode: null,
          nama: null
        }
      }
    },
    watch: {
      tabDetail(to) {
        if (to == 1) {
          this.getBidangKegiatan()
        }
      },
      currentPages(to) {
        this.currentOffset = to - 1
        this.fetchTable()
      },
      currentPageBakum(to) {
        this.currentPageBakum = to
        this.paginateBakum()
      },
      
      
    },
    mounted() {
      // if (localStorage.getItem('profile')) {
        this.fetchData()
    },
    computed : {
      totalPerkumpulan: function() {
        let sum = 0;
        return this.listcountbakum.reduce((sum, listcountbakum) => sum + listcountbakum.jumlahPerkumpulan, 0);
      },
      totalYayasan: function() {
        let sum = 0;
        return this.listcountbakum.reduce((sum, listcountbakum) => sum + listcountbakum.jumlahYayasan, 0);
      }
    },
    methods: {
      click() {},
      clickbakum() {},
      ready() {},
      readybakum() {
        // this.map = this.$refs.myMap.mapObject
      },
      terdaftarormas() {
        this.resetMap()
        this.terdaftar = true
        this.badanHukum = false
        document.getElementById("rekap01").classList.add("active")
        document.getElementById("rekap03").classList.add("deactive")
        document.getElementById("rekap01").classList.remove("deactive")
        document.getElementById("rekap03").classList.remove("active")
      },
      badanhukumormas() {
        this.resetMapBakum()
        this.terdaftar = false
        this.badanHukum = true
        document.getElementById("rekap01").classList.remove("active")
        document.getElementById("rekap03").classList.remove("deactive")
        document.getElementById("rekap01").classList.add("deactive")
        document.getElementById("rekap03").classList.add("active")
        
      },
      getBidangKegiatan() {
        let kodeLength = 'kode' in this.selectedDetail ? this.selectedDetail.kode.length : 0;
        let tipe_wilayah = kodeLength === 0 ? 'all' : kodeLength === 2 ? 'provinsi' : 'kabupaten';
        this.$store.dispatch("getBidangKegiatanRekap", {
          kode: this.selectedDetail.kode,
          tipe: tipe_wilayah
        }).then((res) => {
          this.detailBidangKegiatan = res.data.data
          let dataChartBidangKegiatan = [];
          let labelChartBidangKegiatan = [];
          for (var i in res.data.data) {
            labelChartBidangKegiatan.push(res.data.data[i].nama_jenis_ormas)
            dataChartBidangKegiatan.push(res.data.data[i].total_aktif)
          }
          this.chartDataBidangKegiatan = {
            labels: labelChartBidangKegiatan,
            datasets: [{
              backgroundColor: ['#00FFFF', '#7FFFD4', '#F0FFFF', '#F5F5DC', '#FFE4C4', '#000000', '#FFEBCD', '#0000FF', '#8A2BE2', '#A52A2A', '#DEB887', '#5F9EA0', '#7FFF00', '#D2691E', '#FF7F50', '#6495ED', '#FFF8DC', '#DC143C', '#00FFFF', '#00008B', '#008B8B', '#B8860B', '#A9A9A9', '#006400', '#BDB76B', '#8B008B', '#556B2F', '#FF8C00', '#9932CC', '#8B0000', '#E9967A', '#8FBC8F', '#483D8B', '#2F4F4F', '#00CED1', '#9400D3', '#FF1493', '#00BFFF', '#696969', '#1E90FF', '#B22222', '#FFFAF0', '#228B22', '#FF00FF', '#DCDCDC', '#F8F8FF', '#FFD700', '#DAA520', '#808080', '#008000', '#ADFF2F', '#F0FFF0'],
              data: dataChartBidangKegiatan
            }]
          }
        })
      },
      markClicked(item) {
        if (this.typeCluster[0] == 1) {
          this.locations = []
          this.typeCluster = [2]
          this.paramsTable = {
            "offset": 0,
            "limit": 1000,
            "where": {
              "kode": {
                "like": "%" + item.kode + "%"
              }
            }
          }
          this.fetchData()
          var dz = []
          var lat = item.latlng.lat + ''
          var long = item.latlng.lng + ''
          dz = this.tempProv.find(el => {
            return el.id == item.id
          })
          if (dz) {
            this.zoomIgnite = dz.zoom
            setTimeout(() => {
              this.$refs.myMap.setCenter([lat, long])
            }, 1000);
          }
          this.selected = item
        }
      },
      dataClicked(item) {
        this.locations = []
        this.typeCluster = [2]
        this.paramsTable = {
          "offset": 0,
          "limit": 1000,
          "where": {
            "kode": {
              "like": "%" + item.kode + ".%"
            }
          }
        }
        this.fetchData()
        var ll = JSON.parse(item.latlong)
        var lat = ll.lat.replace(',', '.')
        var long = ll.long.replace(',', '.')
        var dz = []
        dz = this.tempProv.find(el => {
          return el.id == item.id_wilayah
        })
        if (dz) {
          this.$refs.myMap.setCenter([lat, long])
          setTimeout(() => {
            this.zoomIgnite = dz.zoom
          }, 500);
        }
        this.selected = item
        this.selected.text = item.provinsi
        this.currentPage = 1
      },
      dataClickedBakum(ormasbh) {
        Swal.showLoading({
          allowOutsideClick: false
        })
        this.initialLocationBadanHukum = latLng(ormasbh.lat, ormasbh.long)
        this.$refs.myMapBakum.setCenter([ormasbh.lat, ormasbh.long])
        setTimeout(() => {
            this.zoomIgniteBadanHukum = ormasbh.zoom
          }, 500);
        this.tabelbakum = false
        this.kembalitombol = true
        this.listcountbakum = []
        this.locationsbakum = []
        this.dataChartbakum = []
        this.labelChartbakum = []
        this.totalRowsBakum = 0
        var tokens = localStorage.getItem('token')
        this.kodeProv = ormasbh.kode
        this.namaProv = ormasbh.nama
        this.namaKab = ''
        this.kodeProvs = ormasbh.kode
        this.namaProvs = ormasbh.nama
        this.namaKabs = ''
        axios
        .get(this.baseURL_bakkum + 'getcountkabkota?kodeProvinsi=' + ormasbh.kode + '&token=' + tokens)
        .then((listcountbakums) => {
          var i = 0;
          for(i=0; i<listcountbakums.data.data.length; i++){
            this.listcountbakum.push(listcountbakums.data.data[i])
            this.locationsbakum.push({
              latlng: latLng(listcountbakums.data.data[i].lat, listcountbakums.data.data[i].long),
              zoom: listcountbakums.data.data[i].zoom,
              kode: listcountbakums.data.data[i].kode,
              text: listcountbakums.data.data[i].nama,
              total: listcountbakums.data.data[i].totalOrmas,
            })
            this.dataChartbakum.push(listcountbakums.data.data[i].totalOrmas)
            this.labelChartbakum.push(listcountbakums.data.data[i].nama)
              this.totalRowsBakum = listcountbakums.data.data.length
          }
          this.chartDataBakum = {
            labels: this.labelChartbakum,
            datasets: [{
              label: 'Jumlah Ormas Badan Hukum',
              backgroundColor: '#f87979',
              data: this.dataChartbakum
            }]
          }
          this.paginateBakum()
        })
        Swal.close()
      },
      resetMap() {
        this.locations = []
        this.typeCluster = [1]
        this.paramsTable = {
          "offset": 0,
          "limit": 1000,
          "where": {
            "kabupaten_kota": null
          }
        }
        this.fetchData()
        this.initialLocation = latLng(-2.600029, 118.015776)
          this.zoomIgnite = 5
        this.selected = null
      },
      resetMapBakum() {
        Swal.showLoading({
          allowOutsideClick: false
        })

        this.locations = []
        this.listcountbakum =[]
        this.tabelbakum = false
        this.kembalitombol = false
        this.currentPageBakum = 1
        this.totalRowsBakum = 0
        this.dataChartbakum = []
        this.labelChartbakum = []
        this.chartDataBakum = {}
        this.locationsbakum = []
        this.countormasbakum()
        this.initialLocationBadanHukum = latLng(-2.600029, 118.015776)
          // this.$refs.myMapBakum.setCenter(this.initialLocationBadanHukum)
          this.zoomIgniteBadanHukum = 5
        Swal.close()
      },
      fetchData() {
        
        var dd = JSON.parse(localStorage.getItem('profile')).profilUser
        if (dd && dd.masterKesbangpol && dd.masterKesbangpol.kabupaten_kota_kode !== null) {
          this.typeUser = 'kab'
          this.kodeKab = dd.masterKesbangpol.kabupaten_kota_kode
          this.kodeProv = dd.masterKesbangpol.kabupaten_kota_kode.substring(0, 2)
          this.namaKab = dd.masterKesbangpol.nama.replace('Kesbangpol ', '')
          this.paramsTable = {
            "offset": 0,
            "limit": 1000,
            "where": {
              "kode": dd.masterKesbangpol.kabupaten_kota_kode
            }
          }
          this.typeCluster = [2]
          
        // var user = JSON.parse(localStorage.getItem('profile')).profilUser
        this.$store.dispatch("rekapMap", {
          tipe: this.typeCluster,
          kd: this.paramsTable
        }).then((res) => {
          var loc = res.data.data
          if (this.typeCluster[0] == 1) this.tempProv = res.data.data
          if (this.typeCluster[0] == 2) this.tempKab = res.data.data
          for (var i = 0; i < loc.length; i++) {
            this.locations.push({
              id: loc[i].id,
              latlng: latLng(loc[i].latlong.lat, loc[i].latlong.long),
              kode: loc[i].kode,
              text: loc[i].nama
            })
          }
        })
        this.fetchTable()
        } else if (dd && dd.masterKesbangpol && dd.masterKesbangpol.kabupaten_kota_kode == null) {
          if (dd && dd.masterKesbangpol) {
            this.userWilayah.kode = dd.masterKesbangpol.kabupaten_kota_kode ?? dd.masterKesbangpol.provinsi_kode
            this.userWilayah.nama = dd.masterKesbangpol.nama.replace('Kesbangpol ', '')
            const paramGetWilayahUser = {
              limit: 1,
              where: {
                kode: this.userWilayah.kode
              }
            }
            this.$store.dispatch('wilayahCustomFilter', paramGetWilayahUser).then((res) => {
              this.wilayahUser = res.data[0]
              this.$refs.myMap.setCenter([this.wilayahUser.latlong.lat, this.wilayahUser.latlong.long])
              setTimeout(() => {
                this.zoomIgnite = this.wilayahUser.zoom
              }, 500);
            })
          }
          this.typeUser = 'prov'
          this.kodeProv = dd.masterKesbangpol.provinsi_kode
          this.namaProv = dd.masterKesbangpol.nama.replace('Kesbangpol ', '')
          this.paramsTable = {
            "offset": 0,
            "limit": 1000,
            "where": {
              "kode": {
                "like": "%" + dd.masterKesbangpol.provinsi_kode + ".%"
              }
            }
          }
          this.typeCluster = [2]
          
        // var user = JSON.parse(localStorage.getItem('profile')).profilUser
        this.$store.dispatch("rekapMap", {
          tipe: this.typeCluster,
          kd: this.paramsTable
        }).then((res) => {
          var loc = res.data.data
          if (this.typeCluster[0] == 1) this.tempProv = res.data.data
          if (this.typeCluster[0] == 2) this.tempKab = res.data.data
          for (var i = 0; i < loc.length; i++) {
            this.locations.push({
              id: loc[i].id,
              latlng: latLng(loc[i].latlong.lat, loc[i].latlong.long),
              kode: loc[i].kode,
              text: loc[i].nama
            })
          }
        })
        this.fetchTable()
        } else {
          this.typeUser = 'admin'
          
        // var user = JSON.parse(localStorage.getItem('profile')).profilUser
        this.$store.dispatch("rekapMap", {
          tipe: this.typeCluster,
          kd: this.paramsTable
        }).then((res) => {
          var loc = res.data.data
          if (this.typeCluster[0] == 1) this.tempProv = res.data.data
          if (this.typeCluster[0] == 2) this.tempKab = res.data.data
          for (var i = 0; i < loc.length; i++) {
            this.locations.push({
              id: loc[i].id,
              latlng: latLng(loc[i].latlong.lat, loc[i].latlong.long),
              kode: loc[i].kode,
              text: loc[i].nama
            })
          }
        })
        this.fetchTable()
        }
      },
      fetchTable() {
        this.$store.dispatch("rekapTable", this.paramsTable).then((res) => {
          this.listData = res.data.data
          let dataChart = [];
          let labelChart = [];
          for (var i in res.data.data) {
            if (res.data.data[i].kabupaten_kota == null) labelChart.push(res.data.data[i].provinsi)
            if (res.data.data[i].kabupaten_kota != null) labelChart.push(res.data.data[i].kabupaten_kota)
            dataChart.push(res.data.data[i].jumlah_ormas_aktif)
          }
          this.chartData = {
            labels: labelChart,
            datasets: [{
              label: 'Jumlah Ormas Terdafta Aktif',
              backgroundColor: '#f87979',
              data: dataChart
            }]
          }
          this.paginate()
        })
      },
      countormasbakum() {
        
        var tokens = localStorage.getItem('token')
        var user = JSON.parse(localStorage.getItem('profile')).profilUser
        if (user.m_kesbangpol_id === null) {
          this.typeUser = 'admin'
          this.namaProv = ''
          this.namaKab = ''
          this.kodeProv = ''
          this.kodeKab = ''
          this.namaProvs = ''
          this.namaKabs = ''
          this.kodeProvs = ''
          this.kodeKabs = ''
          this.tabelbakum = true
          axios
          .get(this.baseURL_bakkum + 'getcountprov?token=' + tokens)
          .then((listcountbakums) => {
            var i = 0;
            for(i=0; i<listcountbakums.data.data.length; i++){
              this.listcountbakum.push(listcountbakums.data.data[i])
              this.locationsbakum.push({
                    latlng: latLng(listcountbakums.data.data[i].lat, listcountbakums.data.data[i].long),
                    zoom: listcountbakums.data.data[i].zoom,
                    kode: listcountbakums.data.data[i].kode,
                    text: listcountbakums.data.data[i].nama,
                    total: listcountbakums.data.data[i].totalOrmas,
                  })
              this.dataChartbakum.push(listcountbakums.data.data[i].totalOrmas)
              this.labelChartbakum.push(listcountbakums.data.data[i].nama)
              this.totalRowsBakum = listcountbakums.data.data.length
            }
            this.chartDataBakum = {
              labels: this.labelChartbakum,
              datasets: [{
                label: 'Jumlah Ormas Badan Hukum',
                backgroundColor: '#f87979',
                data: this.dataChartbakum
              }]
            }
            this.paginateBakum()
          })
        } else if (user.m_kesbangpol_id !== null && user.m_kesbangpol_id !== null && user.masterKesbangpol.kabupaten_kota_kode === null) {
          this.typeUser = 'prov'
          if (user && user.masterKesbangpol) {
            this.userWilayah.kode = user.masterKesbangpol.kabupaten_kota_kode ?? user.masterKesbangpol.provinsi_kode
            this.userWilayah.nama = user.masterKesbangpol.nama.replace('Kesbangpol ', '')
            const paramGetWilayahUser = {
              limit: 1,
              where: {
                kode: this.userWilayah.kode
              }
            }
            this.$store.dispatch('wilayahCustomFilter', paramGetWilayahUser).then((res) => {
              this.wilayahUser = res.data[0]
              this.$refs.myMapBakum.setCenter([this.wilayahUser.latlong.lat, this.wilayahUser.latlong.long])
              setTimeout(() => {
                this.zoomIgniteBadanHukum = this.wilayahUser.zoom
              }, 500);
            })
          }
          this.namaProv = user.masterKesbangpol.nama.replace('Kesbangpol Provinsi ', '')
          this.namaKab = ''
          this.kodeProv = user.masterKesbangpol.provinsi_kode
          this.kodeKab = ''
          this.namaProvs = user.masterKesbangpol.nama.replace('Kesbangpol Provinsi ', '')
          this.namaKabs = ''
          this.kodeProvs = user.masterKesbangpol.provinsi_kode
          this.kodeKabs = ''
          this.tabelbakum = false
          axios
          .get(this.baseURL_bakkum + 'getcountkabkota?kodeProvinsi=' + this.kodeProv + '&token=' + tokens)
          .then((listcountbakums) => {
            var i = 0;
            for(i=0; i<listcountbakums.data.data.length; i++){
              this.listcountbakum.push(listcountbakums.data.data[i])
              this.locationsbakum.push({
                latlng: latLng(listcountbakums.data.data[i].lat, listcountbakums.data.data[i].long),
                zoom: listcountbakums.data.data[i].zoom,
                kode: listcountbakums.data.data[i].kode,
                text: listcountbakums.data.data[i].nama,
                total: listcountbakums.data.data[i].totalOrmas,
              })
              this.dataChartbakum.push(listcountbakums.data.data[i].totalOrmas)
              this.labelChartbakum.push(listcountbakums.data.data[i].nama)
              this.totalRowsBakum = listcountbakums.data.data.length
            }
            this.chartDataBakum = {
              labels: this.labelChartbakum,
              datasets: [{
                label: 'Jumlah Ormas Badan Hukum',
                backgroundColor: '#f87979',
                data: this.dataChartbakum
              }]
            }
            this.paginateBakum()
            this.currentPage = 1
          })
        } else {
          this.typeUser = 'kab'
          this.namaProv = ''
          this.namaKab = user.masterKesbangpol.nama.replace('Kesbangpol ', '')
          this.kodeProv = user.masterKesbangpol.provinsi_kode
          this.kodeKab = user.masterKesbangpol.kabupaten_kota_kode
          this.namaProvs = ''
          this.namaKabs = user.masterKesbangpol.nama.replace('Kesbangpol ', '')
          this.kodeProvs = user.masterKesbangpol.provinsi_kode
          this.kodeKabs = user.masterKesbangpol.kabupaten_kota_kode
          this.tabelbakum = false
          axios
          .get(this.baseURL_bakkum + 'getrekapkabkotaonly?token=' + tokens + '&kodeKabkota=' + user.masterKesbangpol.kabupaten_kota_kode)
          .then((listcountbakums) => {
            this.listcountbakum.push(listcountbakums.data.data)
            this.locationsbakum.push({
              latlng: latLng(listcountbakums.data.data.lat, listcountbakums.data.data.long),
              zoom: listcountbakums.data.data.zoom,
              kode: listcountbakums.data.data.kode,
              text: listcountbakums.data.data.nama,
              total: listcountbakums.data.data.totalOrmas,
            })
            this.dataChartbakum.push(listcountbakums.data.data.totalOrmas)
            this.labelChartbakum.push(listcountbakums.data.data.nama)
            this.totalRowsBakum = listcountbakums.data.data.length
            this.chartDataBakum = {
              labels: this.labelChartbakum,
              datasets: [{
                label: 'Jumlah Ormas Badan Hukum',
                backgroundColor: '#f87979',
                data: this.dataChartbakum
              }]
            }
            this.paginateBakum()
            this.currentPage = 1
          })
        }
        
      },
      paginate() {
        // human-readable page numbers usuconscurrentPageally start with 1, so we reduce 1 in the first argument
        this.paginateData = this.listData.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      },
      paginateBakum() {
        // human-readable page numbers usuconscurrentPageally start with 1, so we reduce 1 in the first argument
        this.paginateDataBakum = this.listcountbakum.slice((this.currentPageBakum - 1) * this.perPageBakum, this.currentPageBakum * this.perPageBakum);
      },
      cetakPDF() {
        this.loadExPdf = true
        this.$store.dispatch("rekapExportPDF", this.paramsTable).then((res) => {
          this.loadExPdf = false
          if (!res.data.status) {
            Swal.fire({
              title: "Export File Gagal",
              text: res.data.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {})
            return false
          }
          window.open(res.data.meta.urlPath, "_blank");
        }).catch((err) => {
          this.loadExPdf = false
          Swal.fire({
            title: "Export File Gagal",
            text: err.message,
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(() => {})
        })
      },
      cetakEXCEL() {
        this.loadExExcel = true
        this.$store.dispatch("rekapExportExcel", this.paramsTable).then((res) => {
          this.loadExExcel = false
          if (!res.data.status) {
            Swal.fire({
              title: "Export File Gagal",
              text: res.data.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {})
            return false
          }
          window.open(res.data.meta.urlPath, "_blank");
        }).catch((err) => {
          this.loadExExcel = false
          Swal.fire({
            title: "Export File Gagal",
            text: err.message,
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(() => {})
        })
      },
      detailBadanHukum() {
        Swal.showLoading({
          allowOutsideClick: false
        })
        this.kodeProv = ''
          this.kodeKab = ''
          this.namaKab = ''
          this.namaProv = ''
        this.namaRekap = ''
        if (this.typeUser === 'admin' && this.namaProvs !== '' && this.namaKabs === ''){
          this.namaRekap = this.namaProvs
          this.kodeProv = this.kodeProvs
          this.kodeKab = ''
          this.namaKab = ''
          this.namaProv = this.namaProvs
          this.countingNasional = false
          axios
          .get(this.baseURL_bakkum + 'getrekapprovinsi?kodeProvinsi=' + this.kodeProvs)
          .then((resp) => {
            this.totsYayasan = resp.data.data.jenisbadanhukum[1].count;
            this.totsPerkumpulan = resp.data.data.jenisbadanhukum[0].count;
            let dataChartTotalBakumdetail = [this.totsPerkumpulan, this.totsYayasan];
            let labelchartDataTotalBakum0 = resp.data.data.jenisbadanhukum[0].jenis_badan_hukum;
            let labelchartDataTotalBakum1 = resp.data.data.jenisbadanhukum[1].jenis_badan_hukum;
            this.chartDataTotalBakum = {
              labels: ['Jumlah Ormas '+ labelchartDataTotalBakum0, 'Jumlah Ormas '+ labelchartDataTotalBakum1],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartTotalBakumdetail
              }]
            }
            this.totsNamaLengkap = resp.data.data.isnama[0].count;
            if (resp.data.data.isnama.length !== 1){
              this.totsTanpaNama = resp.data.data.isnama[1].count
            } else {
              this.totsTanpaNama = 0
            }
            let dataChartKelengkapanNamaBakum = [this.totsTanpaNama, this.totsNamaLengkap];
            this.chartDataKelengkapanNamaBakum = {
              labels: ['Jumlah Ormas Tanpa Nama', 'Jumlah Ormas Nama Lengkap'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanNamaBakum
              }]
            }
            this.totsAKTALengkap = resp.data.data.isakta[0].count
            if (resp.data.data.isakta.length !== 1){
              this.totsTanpaAKTA = resp.data.data.isakta[1].count
            } else {
              this.totsTanpaAKTA = 0
            }
            let dataChartKelengkapanAKTABakum = [this.totsTanpaAKTA, this.totsAKTALengkap];
            this.chartDataKelengkapanAKTABakum = {
              labels: ['Jumlah Ormas Tanpa Nomor Akta Pendirian', 'Jumlah Ormas Memiliki Nomor Akta Pendirian'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanAKTABakum
              }]
            }
            this.totsSKLengkap = resp.data.data.issk[0].count
            if (resp.data.data.issk.length !== 1){
              this.totsTanpaSK = resp.data.data.issk[1].count
            } else {
              this.totsTanpaSK = 0
            }
            let dataChartKelengkapanSKBakum = [this.totsTanpaSK, this.totsSKLengkap];
            this.chartDataKelengkapanSKBakum = {
              labels: [' Jumlah Ormas Tanpa Nomor SK Kumham', 'Jumlah Ormas Memiliki Nomor SK Kumham'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanSKBakum
              }]
            }
            this.labelKompilasi = []
            this.totsKompilasi = []
            this.labelKompilasi = ["1. Jumlah Ormas Dengan Nama, Nomor Akta Pendirian dan Nomor SK Kumham Lengkap", "2. Jumlah Ormas Dengan Nama dan Nomor Akta Pendirian Lengkap Tanpa Nomor SK Kumham", "3. Jumlah Ormas Dengan Nama dan Nomor SK Kumham Lengkap Tanpa Nomor Akta Pendirian", "4. Jumlah Ormas Dengan Nama Lengkap Tanpa Nomor Akta Pendirian dan Nomor SK Kumham", "5. Jumlah Ormas Dengan Nomor Akta Pendirian dan Nomor SK Kumham Lengkap Tanpa Dengan Nama", "6. Jumlah Ormas Dengan Nomor Akta Pendirian Lengkap Tanpa Dengan Nama dan Nomor SK Kumham", "7. Jumlah Ormas Dengan Nomor SK Kumham Lengkap Tanpa Dengan Nama dan Nomor Akta Pendirian", "8. Jumlah Ormas Tanpa Nama, Nomor Akta Pendirian dan Nomor SK Kumham"]
            var h = 0
            var i = 0
            for(h=0; h<this.labelKompilasi.length; h++){
              let ket = String(h+1)
              let count = 0
                for(i=0; i<resp.data.data.iscekatribut.length; i++){
                  if(resp.data.data.iscekatribut[i].iscekatribut === ket){
                    count = resp.data.data.iscekatribut[i].count
                  }
                }
              this.totsKompilasi.push(count)
            }

            let dataChartKompilasiDataBakum = this.totsKompilasi;
            this.chartDataKompilasiDataBakum = {
              labels: this.labelKompilasi,
              datasets: [{
                backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#A9A9A9', '#8B008B', '#FF1493', '#FF1493'],
                data: dataChartKompilasiDataBakum
              }]
            }

          })
        }
        if (this.typeUser === 'prov' && this.namaProvs !== '' && this.namaKabs === ''){
          this.namaRekap = this.namaProvs
          this.kodeProv = this.kodeProvs
          this.kodeKab = ''
          this.namaKab = ''
          this.namaProv = this.namaProvs
          this.countingNasional = false
          axios
          .get(this.baseURL_bakkum + 'getrekapprovinsi?kodeProvinsi=' + this.kodeProvs)
          .then((resp) => {
            this.totsYayasan = resp.data.data.jenisbadanhukum[1].count;
            this.totsPerkumpulan = resp.data.data.jenisbadanhukum[0].count;
            let dataChartTotalBakumdetail = [this.totsPerkumpulan, this.totsYayasan];
            let labelchartDataTotalBakum0 = resp.data.data.jenisbadanhukum[0].jenis_badan_hukum;
            let labelchartDataTotalBakum1 = resp.data.data.jenisbadanhukum[1].jenis_badan_hukum;
            this.chartDataTotalBakum = {
              labels: ['Jumlah Ormas '+ labelchartDataTotalBakum0, 'Jumlah Ormas '+ labelchartDataTotalBakum1],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartTotalBakumdetail
              }]
            }
            this.totsNamaLengkap = resp.data.data.isnama[0].count;
            if (resp.data.data.isnama.length !== 1){
              this.totsTanpaNama = resp.data.data.isnama[1].count
            } else {
              this.totsTanpaNama = 0
            }
            let dataChartKelengkapanNamaBakum = [this.totsTanpaNama, this.totsNamaLengkap];
            this.chartDataKelengkapanNamaBakum = {
              labels: ['Jumlah Ormas Tanpa Nama', 'Jumlah Ormas Nama Lengkap'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanNamaBakum
              }]
            }
            this.totsAKTALengkap = resp.data.data.isakta[0].count
            if (resp.data.data.isakta.length !== 1){
              this.totsTanpaAKTA = resp.data.data.isakta[1].count
            } else {
              this.totsTanpaAKTA = 0
            }
            let dataChartKelengkapanAKTABakum = [this.totsTanpaAKTA, this.totsAKTALengkap];
            this.chartDataKelengkapanAKTABakum = {
              labels: ['Jumlah Ormas Tanpa Nomor Akta Pendirian', 'Jumlah Ormas Memiliki Nomor Akta Pendirian'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanAKTABakum
              }]
            }
            this.totsSKLengkap = resp.data.data.issk[0].count
            if (resp.data.data.issk.length !== 1){
              this.totsTanpaSK = resp.data.data.issk[1].count
            } else {
              this.totsTanpaSK = 0
            }
            let dataChartKelengkapanSKBakum = [this.totsTanpaSK, this.totsSKLengkap];
            this.chartDataKelengkapanSKBakum = {
              labels: [' Jumlah Ormas Tanpa Nomor SK Kumham', 'Jumlah Ormas Memiliki Nomor SK Kumham'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanSKBakum
              }]
            }
            this.labelKompilasi = []
            this.totsKompilasi = []
            this.labelKompilasi = ["1. Jumlah Ormas Dengan Nama, Nomor Akta Pendirian dan Nomor SK Kumham Lengkap", "2. Jumlah Ormas Dengan Nama dan Nomor Akta Pendirian Lengkap Tanpa Nomor SK Kumham", "3. Jumlah Ormas Dengan Nama dan Nomor SK Kumham Lengkap Tanpa Nomor Akta Pendirian", "4. Jumlah Ormas Dengan Nama Lengkap Tanpa Nomor Akta Pendirian dan Nomor SK Kumham", "5. Jumlah Ormas Dengan Nomor Akta Pendirian dan Nomor SK Kumham Lengkap Tanpa Dengan Nama", "6. Jumlah Ormas Dengan Nomor Akta Pendirian Lengkap Tanpa Dengan Nama dan Nomor SK Kumham", "7. Jumlah Ormas Dengan Nomor SK Kumham Lengkap Tanpa Dengan Nama dan Nomor Akta Pendirian", "8. Jumlah Ormas Tanpa Nama, Nomor Akta Pendirian dan Nomor SK Kumham"]
            var h = 0
            var i = 0
            for(h=0; h<this.labelKompilasi.length; h++){
              let ket = String(h+1)
              let count = 0
                for(i=0; i<resp.data.data.iscekatribut.length; i++){
                  if(resp.data.data.iscekatribut[i].iscekatribut === ket){
                    count = resp.data.data.iscekatribut[i].count
                  }
                }
              this.totsKompilasi.push(count)
            }

            let dataChartKompilasiDataBakum = this.totsKompilasi;
            this.chartDataKompilasiDataBakum = {
              labels: this.labelKompilasi,
              datasets: [{
                backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#A9A9A9', '#8B008B', '#FF1493', '#FF1493'],
                data: dataChartKompilasiDataBakum
              }]
            }

          })
        }
        if(this.typeUser === 'admin' && this.namaProvs === '' && this.namaKabs === ''){
          this.kodeProv = ''
          this.kodeKab = ''
          this.namaKab = ''
          this.namaProv = ''
          this.countingNasional = true
          axios
          .get(this.baseURL_bakkum + 'getrekacountall')
          .then((resp) => {
            this.totsYayasan = resp.data.data.jenisbadanhukum[1].count;
            this.totsPerkumpulan = resp.data.data.jenisbadanhukum[0].count;
            let dataChartTotalBakumdetail = [this.totsPerkumpulan, this.totsYayasan];
            let labelchartDataTotalBakum0 = resp.data.data.jenisbadanhukum[0].jenis_badan_hukum;
            let labelchartDataTotalBakum1 = resp.data.data.jenisbadanhukum[1].jenis_badan_hukum;
            this.chartDataTotalBakum = {
              labels: ['Jumlah Ormas '+ labelchartDataTotalBakum0, 'Jumlah Ormas '+ labelchartDataTotalBakum1],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartTotalBakumdetail
              }]
            }
            this.totsNamaLengkap = resp.data.data.isnama[0].count;
            if (resp.data.data.isnama.length !== 1){
              this.totsTanpaNama = resp.data.data.isnama[1].count
            } else {
              this.totsTanpaNama = 0
            }
            let dataChartKelengkapanNamaBakum = [this.totsTanpaNama, this.totsNamaLengkap];
            this.chartDataKelengkapanNamaBakum = {
              labels: ['Jumlah Ormas Tanpa Nama', 'Jumlah Ormas Nama Lengkap'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanNamaBakum
              }]
            }
            this.totsAKTALengkap = resp.data.data.isakta[0].count
            if (resp.data.data.isakta.length !== 1){
              this.totsTanpaAKTA = resp.data.data.isakta[1].count
            } else {
              this.totsTanpaAKTA = 0
            }
            let dataChartKelengkapanAKTABakum = [this.totsTanpaAKTA, this.totsAKTALengkap];
            this.chartDataKelengkapanAKTABakum = {
              labels: ['Jumlah Ormas Tanpa Nomor Akta Pendirian', 'Jumlah Ormas Memiliki Nomor Akta Pendirian'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanAKTABakum
              }]
            }
            this.totsSKLengkap = resp.data.data.issk[0].count
            if (resp.data.data.issk.length !== 1){
              this.totsTanpaSK = resp.data.data.issk[1].count
            } else {
              this.totsTanpaSK = 0
            }
            let dataChartKelengkapanSKBakum = [this.totsTanpaSK, this.totsSKLengkap];
            this.chartDataKelengkapanSKBakum = {
              labels: [' Jumlah Ormas Tanpa Nomor SK Kumham', 'Jumlah Ormas Memiliki Nomor SK Kumham'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanSKBakum
              }]
            }
            this.labelKompilasi = []
            this.totsKompilasi = []
            this.labelKompilasi = ["1. Jumlah Ormas Dengan Nama, Nomor Akta Pendirian dan Nomor SK Kumham Lengkap", "2. Jumlah Ormas Dengan Nama dan Nomor Akta Pendirian Lengkap Tanpa Nomor SK Kumham", "3. Jumlah Ormas Dengan Nama dan Nomor SK Kumham Lengkap Tanpa Nomor Akta Pendirian", "4. Jumlah Ormas Dengan Nama Lengkap Tanpa Nomor Akta Pendirian dan Nomor SK Kumham", "5. Jumlah Ormas Dengan Nomor Akta Pendirian dan Nomor SK Kumham Lengkap Tanpa Dengan Nama", "6. Jumlah Ormas Dengan Nomor Akta Pendirian Lengkap Tanpa Dengan Nama dan Nomor SK Kumham", "7. Jumlah Ormas Dengan Nomor SK Kumham Lengkap Tanpa Dengan Nama dan Nomor Akta Pendirian", "8. Jumlah Ormas Tanpa Nama, Nomor Akta Pendirian dan Nomor SK Kumham"]
            var h = 0
            var i = 0
            for(h=0; h<this.labelKompilasi.length; h++){
              let ket = String(h+1)
              let count = 0
                for(i=0; i<resp.data.data.iscekatribut.length; i++){
                  if(resp.data.data.iscekatribut[i].iscekatribut === ket){
                    count = resp.data.data.iscekatribut[i].count
                  }
                }
              this.totsKompilasi.push(count)
            }
            let dataChartKompilasiDataBakum = this.totsKompilasi;
            this.chartDataKompilasiDataBakum = {
              labels: this.labelKompilasi,
              datasets: [{
                backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#A9A9A9', '#8B008B', '#FF1493', '#FF1493'],
                data: dataChartKompilasiDataBakum
              }]
            }

          })
        }
        Swal.close()
        this.$bvModal.show('modal-rekap-bakum')
      },
      detail(item = {}) {
        Swal.showLoading({
          allowOutsideClick: false
        })
        let kode = item?.kode;
        if (!kode && this.typeUser == 'prov') {
          kode = this.userWilayah.kode ? this.userWilayah.kode : null;
        }
        this.$store.dispatch("rekapDetail", kode).then((res) => {
          this.selectedDetail = item;
          this.detailRekapitulasi = res.data
          // Manually set provinsi and kode if not from table rows
          if (!kode && this.typeUser == 'prov') {
            this.selectedDetail['provinsi'] = this.userWilayah.nama
            this.selectedDetail['kode'] = this.userWilayah.kode
          }
          let jumlahAktif = ('jumlah_ormas_aktif' in this.detailRekapitulasi) ? this.detailRekapitulasi.jumlah_ormas_aktif : this.detailRekapitulasi.status_skt[0].status.aktif;
          let jumlahTidakAktif = ('jumlah_ormas_tidak_aktif' in this.detailRekapitulasi) ? this.detailRekapitulasi.jumlah_ormas_tidak_aktif : this.detailRekapitulasi.status_skt[0].status.tidak_aktif;
          let dataChartTotal = [jumlahAktif, jumlahTidakAktif];
          this.chartDataTotal = {
            labels: ['Jumlah Ormas Aktif', 'Jumlah Ormas Tidak Aktif'],
            datasets: [{
              backgroundColor: ['#41B883', '#E46651'],
              data: dataChartTotal
            }]
          }
          this.listDetail = res.data
          let dataChartCiriKhusus = [];
          let labelChartCiriKhusus = [];
          for (var i in res.data.ciri_khusus) {
            labelChartCiriKhusus.push(res.data.ciri_khusus[i].label)
            dataChartCiriKhusus.push(res.data.ciri_khusus[i].status.aktif)
          }
          this.chartDataCiriKhusus = {
            labels: labelChartCiriKhusus,
            datasets: [{
              backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#A9A9A9', '#8B008B', '#FF1493'],
              data: dataChartCiriKhusus
            }]
          }
          let dataChartJenjangOrmas = [];
          let labelChartJenjangOrmas = [];
          for (var i in res.data.jenjang_ormas) {
            labelChartJenjangOrmas.push(res.data.jenjang_ormas[i].label)
            dataChartJenjangOrmas.push(res.data.jenjang_ormas[i].status.aktif)
          }
          this.chartDataJenjangOrmas = {
            labels: labelChartJenjangOrmas,
            datasets: [{
              backgroundColor: ['#41B883', '#E46651'],
              data: dataChartJenjangOrmas
            }]
          }
          let dataChartStatusKegiatan = [];
          let labelChartStatusKegiatan = [];
          for (var i in res.data.status_kegiatan) {
            labelChartStatusKegiatan.push(res.data.status_kegiatan[i].label)
            dataChartStatusKegiatan.push(res.data.status_kegiatan[i].status.aktif)
          }
          this.chartDataStatusKegiatan = {
            labels: labelChartStatusKegiatan,
            datasets: [{
              backgroundColor: ['#41B883', '#E46651'],
              data: dataChartStatusKegiatan
            }]
          }
          let dataChartDalamSengketa = [];
          let labelChartDalamSengketa = [];
          for (var i in res.data.ormas_dalam_sengketa) {
            labelChartDalamSengketa.push(res.data.ormas_dalam_sengketa[i].label)
            dataChartDalamSengketa.push(res.data.ormas_dalam_sengketa[i].status.aktif)
          }
          this.chartDataDalamSengketa = {
            labels: labelChartDalamSengketa,
            datasets: [{
              backgroundColor: ['#41B883', '#E46651'],
              data: dataChartDalamSengketa
            }]
          }
          let dataChartStatusSekretariat = [];
          let labelChartStatusSekretariat = [];
          for (var i in res.data.status_sekretariat) {
            labelChartStatusSekretariat.push(res.data.status_sekretariat[i].label)
            dataChartStatusSekretariat.push(res.data.status_sekretariat[i].status.aktif)
          }
          this.chartDataStatusSekretariat = {
            labels: labelChartStatusSekretariat,
            datasets: [{
              backgroundColor: ['#41B883', '#E46651', '#00D8FF'],
              data: dataChartStatusSekretariat
            }]
          }
          this.$bvModal.show('modal-rekap')
          
        })
        Swal.close()
      },
      detailBakum(item) {
        Swal.showLoading({
          allowOutsideClick: false
        })
        this.kodeProv = ''
          this.kodeKab = ''
          this.namaKab = ''
          this.namaProv = ''
        this.countingNasional = false
        if (item.kode !== "" && item.kode_ref !== ""){
          this.kodeProv = item.kode_ref
          this.kodeKab = item.kode
          this.namaKab = item.nama
          this.namaProv = ''
          this.namaRekap = item.nama
          axios
          .get(this.baseURL_bakkum + 'getrekapkabkota?kodeProvinsi=' + item.kode_ref + '&kodeKabkota=' + item.kode)
          .then((resp) => {
            this.totsYayasan = resp.data.data.jenisbadanhukum[1].count;
            this.totsPerkumpulan = resp.data.data.jenisbadanhukum[0].count;
            let dataChartTotalBakumdetail = [this.totsPerkumpulan, this.totsYayasan];
            let labelchartDataTotalBakum0 = resp.data.data.jenisbadanhukum[0].jenis_badan_hukum;
            let labelchartDataTotalBakum1 = resp.data.data.jenisbadanhukum[1].jenis_badan_hukum;
            this.chartDataTotalBakum = {
              labels: ['Jumlah Ormas '+ labelchartDataTotalBakum0, 'Jumlah Ormas '+ labelchartDataTotalBakum1],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartTotalBakumdetail
              }]
            }
            this.totsNamaLengkap = resp.data.data.isnama[0].count;
            if (resp.data.data.isnama.length !== 1){
              this.totsTanpaNama = resp.data.data.isnama[1].count
            } else {
              this.totsTanpaNama = 0
            }
            let dataChartKelengkapanNamaBakum = [this.totsTanpaNama, this.totsNamaLengkap];
            this.chartDataKelengkapanNamaBakum = {
              labels: ['Jumlah Ormas Tanpa Nama', 'Jumlah Ormas Nama Lengkap'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanNamaBakum
              }]
            }
            this.totsAKTALengkap = resp.data.data.isakta[0].count
            if (resp.data.data.isakta.length !== 1){
              this.totsTanpaAKTA = resp.data.data.isakta[1].count
            } else {
              this.totsTanpaAKTA = 0
            }
            let dataChartKelengkapanAKTABakum = [this.totsTanpaAKTA, this.totsAKTALengkap];
            this.chartDataKelengkapanAKTABakum = {
              labels: ['Jumlah Ormas Tanpa Nomor Akta Pendirian', 'Jumlah Ormas Memiliki Nomor Akta Pendirian'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanAKTABakum
              }]
            }
            this.totsSKLengkap = resp.data.data.issk[0].count
            if (resp.data.data.issk.length !== 1){
              this.totsTanpaSK = resp.data.data.issk[1].count
            } else {
              this.totsTanpaSK = 0
            }
            let dataChartKelengkapanSKBakum = [this.totsTanpaSK, this.totsSKLengkap];
            this.chartDataKelengkapanSKBakum = {
              labels: [' Jumlah Ormas Tanpa Nomor SK Kumham', 'Jumlah Ormas Memiliki Nomor SK Kumham'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanSKBakum
              }]
            }
            this.labelKompilasi = []
            this.totsKompilasi = []
            this.labelKompilasi = ["1. Jumlah Ormas Dengan Nama, Nomor Akta Pendirian dan Nomor SK Kumham Lengkap", "2. Jumlah Ormas Dengan Nama dan Nomor Akta Pendirian Lengkap Tanpa Nomor SK Kumham", "3. Jumlah Ormas Dengan Nama dan Nomor SK Kumham Lengkap Tanpa Nomor Akta Pendirian", "4. Jumlah Ormas Dengan Nama Lengkap Tanpa Nomor Akta Pendirian dan Nomor SK Kumham", "5. Jumlah Ormas Dengan Nomor Akta Pendirian dan Nomor SK Kumham Lengkap Tanpa Dengan Nama", "6. Jumlah Ormas Dengan Nomor Akta Pendirian Lengkap Tanpa Dengan Nama dan Nomor SK Kumham", "7. Jumlah Ormas Dengan Nomor SK Kumham Lengkap Tanpa Dengan Nama dan Nomor Akta Pendirian", "8. Jumlah Ormas Tanpa Nama, Nomor Akta Pendirian dan Nomor SK Kumham"]
            var h = 0
            var i = 0
            for(h=0; h<this.labelKompilasi.length; h++){
              let ket = String(h+1)
              let count = 0
                for(i=0; i<resp.data.data.iscekatribut.length; i++){
                  if(resp.data.data.iscekatribut[i].iscekatribut === ket){
                    count = resp.data.data.iscekatribut[i].count
                  }
                }
              this.totsKompilasi.push(count)
            }
            let dataChartKompilasiDataBakum = this.totsKompilasi;
            this.chartDataKompilasiDataBakum = {
              labels: this.labelKompilasi,
              datasets: [{
                backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#A9A9A9', '#8B008B', '#FF1493', '#FF1493'],
                data: dataChartKompilasiDataBakum
              }]
            }

          })
        }
        if (item.kode !== "" && item.kode_ref === ""){
        this.kodeProv = item.kode
        this.kodeKab = item.kode_ref
        this.namaProv = item.nama
        this.namaRekap = item.nama
          axios
          .get(this.baseURL_bakkum + 'getrekapprovinsi?kodeProvinsi=' + item.kode)
          .then((resp) => {
            this.totsYayasan = resp.data.data.jenisbadanhukum[1].count;
            this.totsPerkumpulan = resp.data.data.jenisbadanhukum[0].count;
            let dataChartTotalBakumdetail = [this.totsPerkumpulan, this.totsYayasan];
            let labelchartDataTotalBakum0 = resp.data.data.jenisbadanhukum[0].jenis_badan_hukum;
            let labelchartDataTotalBakum1 = resp.data.data.jenisbadanhukum[1].jenis_badan_hukum;
            this.chartDataTotalBakum = {
              labels: ['Jumlah Ormas '+ labelchartDataTotalBakum0, 'Jumlah Ormas '+ labelchartDataTotalBakum1],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartTotalBakumdetail
              }]
            }
            this.totsNamaLengkap = resp.data.data.isnama[0].count;
            if (resp.data.data.isnama.length !== 1){
              this.totsTanpaNama = resp.data.data.isnama[1].count
            } else {
              this.totsTanpaNama = 0
            }
            let dataChartKelengkapanNamaBakum = [this.totsTanpaNama, this.totsNamaLengkap];
            this.chartDataKelengkapanNamaBakum = {
              labels: ['Jumlah Ormas Tanpa Nama', 'Jumlah Ormas Nama Lengkap'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanNamaBakum
              }]
            }
            this.totsAKTALengkap = resp.data.data.isakta[0].count
            if (resp.data.data.isakta.length !== 1){
              this.totsTanpaAKTA = resp.data.data.isakta[1].count
            } else {
              this.totsTanpaAKTA = 0
            }
            let dataChartKelengkapanAKTABakum = [this.totsTanpaAKTA, this.totsAKTALengkap];
            this.chartDataKelengkapanAKTABakum = {
              labels: ['Jumlah Ormas Tanpa Nomor Akta Pendirian', 'Jumlah Ormas Memiliki Nomor Akta Pendirian'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanAKTABakum
              }]
            }
            this.totsSKLengkap = resp.data.data.issk[0].count
            if (resp.data.data.issk.length !== 1){
              this.totsTanpaSK = resp.data.data.issk[1].count
            } else {
              this.totsTanpaSK = 0
            }
            let dataChartKelengkapanSKBakum = [this.totsTanpaSK, this.totsSKLengkap];
            this.chartDataKelengkapanSKBakum = {
              labels: [' Jumlah Ormas Tanpa Nomor SK Kumham', 'Jumlah Ormas Memiliki Nomor SK Kumham'],
              datasets: [{
                backgroundColor: ['#41B883', '#E46651'],
                data: dataChartKelengkapanSKBakum
              }]
            }
            this.labelKompilasi = []
            this.totsKompilasi = []
            this.labelKompilasi = ["1. Jumlah Ormas Dengan Nama, Nomor Akta Pendirian dan Nomor SK Kumham Lengkap", "2. Jumlah Ormas Dengan Nama dan Nomor Akta Pendirian Lengkap Tanpa Nomor SK Kumham", "3. Jumlah Ormas Dengan Nama dan Nomor SK Kumham Lengkap Tanpa Nomor Akta Pendirian", "4. Jumlah Ormas Dengan Nama Lengkap Tanpa Nomor Akta Pendirian dan Nomor SK Kumham", "5. Jumlah Ormas Dengan Nomor Akta Pendirian dan Nomor SK Kumham Lengkap Tanpa Dengan Nama", "6. Jumlah Ormas Dengan Nomor Akta Pendirian Lengkap Tanpa Dengan Nama dan Nomor SK Kumham", "7. Jumlah Ormas Dengan Nomor SK Kumham Lengkap Tanpa Dengan Nama dan Nomor Akta Pendirian", "8. Jumlah Ormas Tanpa Nama, Nomor Akta Pendirian dan Nomor SK Kumham"]
            var h = 0
            var i = 0
            for(h=0; h<this.labelKompilasi.length; h++){
              let ket = String(h+1)
              let count = 0
                for(i=0; i<resp.data.data.iscekatribut.length; i++){
                  if(resp.data.data.iscekatribut[i].iscekatribut === ket){
                    count = resp.data.data.iscekatribut[i].count
                  }
                }
              this.totsKompilasi.push(count)
            }

            let dataChartKompilasiDataBakum = this.totsKompilasi;
            this.chartDataKompilasiDataBakum = {
              labels: this.labelKompilasi,
              datasets: [{
                backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#A9A9A9', '#8B008B', '#FF1493', '#FF1493'],
                data: dataChartKompilasiDataBakum
              }]
            }

          })
        }
        this.$bvModal.show('modal-rekap-bakum')
        Swal.close()
      },
    }
  };
</script>
<style>
  @import "~leaflet/dist/leaflet.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
  /* html, body {
    height: 100%;
    margin: 0;
  } */
  
</style>